import React from 'react';

const Loader: React.FC = () => (
	<div className="container d-flex align-items-center justify-content-center">
		<div className="spinner-grow text-primary m-1" role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
		<div className="spinner-grow text-primary m-1" role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
		<div className="spinner-grow text-primary m-1" role="status">
			<span className="visually-hidden">Loading...</span>
		</div>
	</div>
);

export default Loader;
