import React, { useState } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Loader from './loader';
import RecentPress from './contactRecentPress';
import { StaticImage } from 'gatsby-plugin-image';
import { Timeline } from 'react-twitter-widgets';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

interface RecentPressMetadata {
	id: number;
	link: string;
	text: string;
}

interface RecentPressEntry {
	node: {
		metadata: RecentPressMetadata;
	};
}

interface QueryResult {
	allCosmicjsRecentpressentries: {
		edges: RecentPressEntry[];
	};
}

const ContactComponent = () => {
	const [isLoading, setIsLoading] = useState(true);

	// GraphQL query to fetch data from Cosmic JS
	const recentPressQueryData = useStaticQuery<QueryResult>(graphql`
		query {
			allCosmicjsRecentpressentries(
				sort: { fields: [metadata___id], order: ASC }
			) {
				edges {
					node {
						metadata {
							id
							link
							text
						}
					}
				}
			}
		}
	`);

	const recentPressData = recentPressQueryData.allCosmicjsRecentpressentries.edges.map(
		(edge: RecentPressEntry) => edge.node.metadata
	);

	return (
		<div className="ContactComponent">
			{/* Page Title */}
			<div className="title-section text-left text-sm-center">
				<h1>
					Contact <span className="main-color">Matt Murphy</span>
				</h1>
			</div>
			{/* Contact Info */}
			<div className="container p-4 p-sm-0">
				<div className="row">
					{/* Row 1 */}
					<div className="col-12 col-lg-5 contact-info">
						<h3 className="text-uppercase contact-title mb-0 ft-wt-600 pb-3">
							Let's connect
						</h3>
						<p className="open-sans-font custom-span-contact position-relative">
							<a
								href="mailto:mattmurphy165@gmail.com"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon
									icon={faEnvelope}
									className="position-absolute contact-icon"
								/>
							</a>
							<span className="d-block">Send an email</span>
							MattMurphy165@gmail.com
						</p>
						<p className="open-sans-font custom-span-contact position-relative">
							<a
								href="https://twitter.com/Matt_Murphy04"
								target="_blank"
								rel="noopener noreferrer"
							>
								<FontAwesomeIcon
									icon={faTwitter}
									className="position-absolute contact-icon"
								/>
							</a>
							<span className="d-block">Follow on Twitter</span>
							@Matt_Murphy04
						</p>
					</div>

					<div className="col-12 col-lg-7">
						<StaticImage
							src="../../static/images/MM4_Contact.jpeg"
							alt="Matt broadcasting"
							className="contact-img"
						/>
					</div>
				</div>
				{/* Row 2 */}
				<div className="row mt-4 mb-5">
					<div className="col-12">
						<h3>Recent Press</h3>
						{recentPressData.map((link: RecentPressMetadata) => (
							<RecentPress key={link.id} link={link.link} text={link.text} />
						))}
					</div>
				</div>
				{/* Row 3 */}
				<div className="row mt-4">
					<div className="col-12 col-lg-5">
						{isLoading && <Loader />}
						<Timeline
							dataSource={{
								sourceType: 'profile',
								screenName: 'Matt_Murphy04'
							}}
							options={{
								height: '450',
								width: '430',
								theme: 'dark'
							}}
							onLoad={() => setIsLoading(false)}
						/>
					</div>

					<div className="col-12 col-lg-7 d-none d-lg-block">
						<StaticImage
							src="../../static/images/MM5_Contact.jpeg"
							alt="Matt Murphy broadcasting"
							className="img-fluid contact-img2"
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ContactComponent;
